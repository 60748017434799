var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-1"
  }, [_c('div-table', {
    attrs: {
      "config": _vm.configList,
      "idWharhouse": _vm.idWharhouse,
      "currentUser": _vm.currentUser,
      "data": _vm.expeditions,
      "CheckExpeditionRecived": _vm.CheckExpeditionRecived,
      "element": _vm.element,
      "statusExpedition": _vm.statusExpedition,
      "transports": _vm.transports,
      "loading": _vm.loading,
      "paginate": _vm.paginate
    },
    on: {
      "searchSellers": _vm.searchSellers,
      "CheckQteSentExist": _vm.CheckQteSentExist,
      "reset": _vm.reset,
      "refresh": _vm.refresh,
      "showProducts": _vm.showProducts,
      "filtrer": _vm.filtrer,
      "search": _vm.search,
      "paginatation": _vm.paginatation
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }