<template>
  <div class="p-1">
    <div-table
      :config="configList"
      :idWharhouse="idWharhouse"
      :currentUser="currentUser"
      :data="expeditions"
      @searchSellers="searchSellers"
      :CheckExpeditionRecived="CheckExpeditionRecived"
      @CheckQteSentExist="CheckQteSentExist"
      :element="element"
      :statusExpedition="statusExpedition"
      :transports="transports"
      @reset="reset"
      @refresh="refresh"
      @showProducts="showProducts"
      @filtrer="filtrer"
      @search="search"
      :loading="loading"
      :paginate="paginate"
      @paginatation="paginatation"
    ></div-table>
  </div>
</template>
<script>
//import button from "../../components/~/button.vue";
import _ from "lodash";
import config from "./config";
export default {
  props: {
    currentUser: { type: Object },
    idWharhouse: { type: Object },
  },
  data() {
    return {
      configList: config.list,
      loading: false,
      formData: {
        carrier: {},
      },
      expeditions: [],
      element: {},
      filters: {},
      show_modal: false,
      limit: 10,
      paginate: {
        total: 0,
        currentpage: 1,
        lastpage: 0,
        per_page: 0,
      },
      //currentUser: {},
      sellers: [],
      statusExpedition: ["expedited", "received", "cancelled"],
      transports: ["road", "railway", "air", "maritime"],
      CheckExpeditionRecived: false,
    };
  },
  async mounted() {
    //await this.getUser();

    if (this.$f.QueryStringToobject())
      this.filters = this.$f.QueryStringToobject();
    else
      this.$f.objectToQueryString({
        limit: this.limit,
        country_to: await this.warhouseSelected,
      });

    if (this.filters.name) await this.search(this.filters.name);
    else await this.getExpeditions(this.filters);
    if (this.currentUser.type != "Seller") {
      await this.getSellers();
      this.setDataSelect("seller", this.sellers, null);
    }
    this.setDataSelect("status", this.statusExpedition, null);
    this.setDataSelect("transportMode", this.transports, null);
    if (this.currentUser.type == "Seller") {
      let pos = this.configList.columns
        .map(function (e) {
          return e.name;
        })
        .indexOf("Seller");
      if (pos != -1) this.configList.columns.splice(pos, 1);
    }
  },
  computed: {
    warhouseSelected() {
      return this.$store.getters["wharhouse/warhouseSelected"]({
        user: _.pick(this.currentUser, ["countries"]),
      });
    },
  },
  watch: {
    warhouseSelected: async function (oldVal, newVal) {
      //  await this.reset();
      //  if (this.currentUser.type != "Seller") {  await this.getSellers();this.setDataSelect("seller", this.sellers, null);}
    },
    idWharhouse: async function (oldVal, newVal) {
      this.idWharhouse = oldVal;
      await this.reset();
      if (this.currentUser.type != "Seller") {
        await this.getSellers();
        this.setDataSelect("seller", this.sellers, null);
      }
    },
  },
  methods: {
    setDataSelect(model, data, splice) {
      for (var i = 0; i < this.configList.filters.elements.champs.length; i++) {
        if (
          this.configList.filters.elements.champs[i].type === "select" &&
          this.configList.filters.elements.champs[i].key === model
        ) {
          if (!splice) {
            this.configList.filters.elements.champs[i].values = data;
          } else {
            this.configList.filters.elements.champs.splice(i, 1);
          }
        }
      }
    },
    async CheckQteSentExist(item) {
      this.CheckExpeditionRecived = false;
      if (item.status == "received") {
        for (let i in item.details) {
          const filters = {
            product: item.details[i].product._id,
            expedition: item._id,
          };
          const res = await this.$server.search("stockHistories", filters);

          if (typeof res.content.results !== "undefined") {
            this.CheckExpeditionRecived = true;
          }
        }
      }
    },
    async getSellers() {
      const filter = {
        type: "Seller",
        countries: await this.warhouseSelected,
      };
      if (this.currentUser.type != "Seller") {
        const res = await this.$server.search("users", filter);
        if (res.content.results) this.sellers = res.content.results;
        else this.sellers = [];
      }
    },
    searchSellers: _.debounce(async function (search, event) {
      const filter = {
        type: "Seller",
        limit: this.limit,
        countries: await this.warhouseSelected,
        name: search,
      };
      const res = await this.$server.find("users", filter);

      if (res.content.results) this.sellers = res.content.results;
      else this.sellers = [];
      this.setDataSelect("seller", this.sellers, null);
    }, 500),
    async reset(data) {
      let lmitt = this.limit;
      if (this.$f.QueryStringToobject())
        this.filters = this.$f.QueryStringToobject();
      if (this.filters["limit"]) lmitt = this.filters["limit"];
      this.filters["page"] = 1;
      this.$f.objectToQueryString({
        limit: lmitt,
        country_to: await this.warhouseSelected,
      });
      await this.getExpeditions({ limit: lmitt });
    },
    async filtrer(data) {
      if (this.$f.QueryStringToobject())
        this.filters = this.$f.QueryStringToobject();
      delete this.filters.page;
      delete this.filters.name;
      this.paginate.currentpage = 1;
      if (!this.filters["limit"]) this.filters["limit"] = this.limit;
      if (data.status) this.filters.status = data.status;
      else delete this.filters.status;
      if (data.transportMode) this.filters.transportMode = data.transportMode;
      else delete this.filters.transportMode;
      if (data.numberOfPackages)
        this.filters.numberOfPackages = data.numberOfPackages;
      else delete this.filters.numberOfPackages;
      if (data.seller) this.filters.seller = data.seller._id;
      else delete this.filters.seller;
      this.$f.objectToQueryString(this.filters);
      await this.getExpeditions(this.filters);
    },
    async search(data) {
      this.paginate = { total: 0, currentpage: 1, lastpage: 1, per_page: 0 };
      let limitt = this.limit;
      if (this.filters["limit"]) limitt = this.filters["limit"];

      const search = {
        name: data.trim(),
        limit: parseInt(limitt),
      };
      if (this.currentUser.type == "Seller") {
        search.seller = this.currentUser._id;
      }
      search.country_to = await this.warhouseSelected;
      this.$f.objectToQueryString(search);
      const res = await this.$server.find("expeditions", search);
      if (res.content.results) {
        this.expeditions = res.content.results;
        this.paginate.total = res.content.total;
        this.paginate.lastpage = res.content.last_page;
        this.paginate.per_page = res.content.per_page;
        this.paginate.currentpage = res.content.current_page;
        for (const element of this.expeditions) {
          element.arrivalDate = this.$moment(element.arrivalDate).format(
            "yyyy-MM-DDTHH:mm"
          );
        }
      } else {
        this.expeditions = [];
        this.paginate = { total: 0, currentpage: 1, lastpage: 1, per_page: 0 };
      }
    },
    async getUser() {
      const res = await this.$me;
      this.currentUser = res.content;
    },
    async getExpeditions(filters) {
      if (this.currentUser.type == "Seller") {
        filters.seller = this.currentUser._id;
      }
      filters.country_to = await this.warhouseSelected;
      this.loading = true;
      const res = await this.$server.search("expeditions", filters);
      this.loading = false;
      if (res.content.results) {
        this.expeditions = res.content.results;
        this.paginate.total = res.content.total;
        this.paginate.lastpage = res.content.last_page;
        this.paginate.per_page = res.content.per_page;
        this.paginate.currentpage = res.content.current_page;
        for (const element of this.expeditions) {
          element.arrivalDate = this.$moment(element.arrivalDate).format(
            "yyyy-MM-DDTHH:mm"
          );
        }
      } else {
        this.expeditions = [];
        this.paginate = { total: 0, currentpage: 1, lastpage: 1, per_page: 0 };
      }
    },
    async refresh() {
      if (this.$f.QueryStringToobject())
        this.filters = this.$f.QueryStringToobject();
      this.$f.objectToQueryString(this.filters);
      if (this.filters.name) await this.search(this.filters.name);
      else await this.getExpeditions(this.filters);
      if (
        this.filters["page"] &&
        this.filters["page"] > 1 &&
        this.expeditions.length == 0
      ) {
        this.filters["page"] = --this.filters["page"];
        this.$f.objectToQueryString(this.filters);
        await this.getExpeditions(this.filters);
      }
    },
    showProducts(index) {
      if (this.element !== this.expeditions[index]) {
        this.element = this.expeditions[index];
      } else this.element = null;
    },
    async remove(id) {
       this.$swal.fire({
            title: this.$t("confirm_delete_expedition"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then(async (result) => {
            if (result.isConfirmed) {
          const resp = await this.$server.delete("expeditions", { id: id });
          await this.refresh();
          alert(this.$t("expedition_deleted"), "success");
        }
      });
    },
    async paginatation(direction) {
      var page = 0;
      if (direction == "next") {
        page = ++this.paginate.currentpage;
      } else {
        page = --this.paginate.currentpage;
      }
      if (this.$f.QueryStringToobject())
        this.filters = this.$f.QueryStringToobject();
      this.filters["page"] = page;
      this.$f.objectToQueryString(this.filters);

      this.getExpeditions(this.filters);
    },
  },
};
</script>
